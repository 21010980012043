import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

export const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    borderRadius: "12px",
    padding: "32px",
    gap: "16px",
    display: "column",
    gridTemplateColumns: " 20% 60% 20%",
    alignItems: "flex-start",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
	marginTop: '10%',
  },

  title: {
    "&.MuiTypography-root": {
      fontFamily: "Marianne-ExtraBold",
      fontSize: "34px",
      fontStyle: "normal",
      fontWeight: 800,
      lineHeight: "32px",
      color: "#161616",
      width: "100%",
	   height: "40px",
      alignSelf: "center",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
    },
  },
  svg: {
    maxWidth: "100%",
    height: "auto",
    textAlign: "center",
  },
});

export const Root = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "12px",
  padding: "30px",
  display: "column",
  gridTemplateColumns: " 20% 60% 20%",
  alignItems: "flex-start",
  marginTop: "4px",

  [theme.breakpoints.up("md")]: {
    width: "40%",
  },
}));

export const Bottons = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    Grid: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
    },
  },
}));

export const Logoo = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    Typography: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
    },
  },
  [theme.breakpoints.down("md")]: {
    svg: {
      maxWidth: "70%",
      height: "auto",
      textAlign: "center",
    },
  },
}));
