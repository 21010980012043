import React from "react";
import { CovoiceDetails } from "../components/covoiceDetails/CovoiceDetail";
import { logoCovoice as ReactLogo } from "../components/Icons/logoCovoice";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Logoo, Root, useStyles } from "../styles/stylePage";

export const CovoicePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div style={{minHeight: window.screen.height - 250, display: "flex"}}>
      <div className={isWeb ? classes.container : classes.containerMobile}>
        <Grid>
          <Typography className={classes.title}>Covoice</Typography>
          <Grid className={classes.svg}>
            <Logoo>
              <ReactLogo />
            </Logoo>
          </Grid>
        </Grid>
        <Root>
          <CovoiceDetails />
        </Root>
      </div>
    </div>
  );
};

export default CovoicePage;
