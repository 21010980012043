import React from "react";

export const appleLogo = () => {
	return (
		<svg
			width="18"
			height="22"
			viewBox="0 0 18 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.7973 11.5804C14.819 9.89936 15.7217 8.30876 17.1539 7.4283C16.2504 6.13798 14.7371 5.31988 13.1627 5.27062C11.4835 5.09436 9.85552 6.27545 8.99981 6.27545C8.12754 6.27545 6.81004 5.28812 5.39131 5.31731C3.54206 5.37705 1.81809 6.42844 0.918393 8.04518C-1.01561 11.3936 0.426982 16.3146 2.27959 19.0213C3.20649 20.3466 4.28977 21.8271 5.70722 21.7746C7.09428 21.717 7.61232 20.8901 9.28662 20.8901C10.9454 20.8901 11.4314 21.7746 12.8776 21.7412C14.366 21.717 15.3038 20.4099 16.1981 19.072C16.8641 18.1277 17.3766 17.084 17.7166 15.9795C15.9674 15.2397 14.7994 13.4795 14.7973 11.5804Z"
				fill="white"
			/>
			<path
				d="M12.0656 3.49062C12.8772 2.51641 13.277 1.26423 13.1802 0C11.9403 0.13022 10.7951 0.722779 9.97259 1.65961C9.1683 2.57494 8.74975 3.80509 8.82888 5.021C10.0692 5.03377 11.2885 4.45727 12.0656 3.49062Z"
				fill="white"
			/>
		</svg>
	);
};
