import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useStyles } from "../../styles/Detailsstyles";
import { appleLogo as ReactLogoapple } from "../Icons/appleLogo";
import { ggPlayLogo as ReactLogoggplay } from "../Icons/ggPlayLogo";
import {
  Description,
  URL_APP_STORE,
  URL_GOOGLE_PLAY,
} from "../../utils/constants";

export const CovoiceDetails = () => {
  const classes = useStyles();
   const redirectUrlPLAYSTORE = () => {
	    window.open(URL_APP_STORE, "_self");
   };

   const redirectUrlGoogle = () => {
	    window.open(URL_GOOGLE_PLAY, "_self");
   };
  return (
    <>
      <Grid>
        <Typography className={classes.title}>
          Présentation du projet Covoice
        </Typography>
      </Grid>

      <Grid
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            marginTop: "5px",
          },
        })}
      >
        {Description.map((desc, index) => (
          <Typography key={index} className={classes.paragraphe}>
            {desc}
          </Typography>
        ))}
      </Grid>
      <Grid
        sx={(theme) => ({
          [theme.breakpoints.up("sm")]: {
            marginTop: "20px",
            height: "50px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          },
          [theme.breakpoints.down("sm")]: {
            marginTop: "10px",
            height: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "25px",
          },
        })}
      >
        <Button
          variant="contained"
          onClick={redirectUrlPLAYSTORE}
          sx={{
            backgroundColor: "black",
            height: "50px",
            width: "140px",
          }}
          className={classes.button}
          startIcon={<ReactLogoapple />}
        >
          <Grid
            sx={{
              display: "grid",
            }}
          >
            <Typography className={classes.downloadText}>
              Télécharger sur
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 700,
                textTransform: "capitalize",
                marginTop: "-4px",
                fontFamily: "metropolis-Medium",
              }}
            >
              <a
                style={{ textDecoration: "none" }}className={classes.downloadTextStyle}
                href={URL_APP_STORE}
              >
                App Store
              </a>
            </Typography>
          </Grid>
        </Button>

        <Button
          variant="contained"
          onClick={redirectUrlGoogle}
          sx={(theme) => ({
            [theme.breakpoints.up("sm")]: {
              marginLeft: "20px",
              backgroundColor: "black",
              height: "50px",
              width: "140px",
            },
            [theme.breakpoints.down("sm")]: {
              marginTop: "10px",
              marginBottom: "50px",
              backgroundColor: "black",
              height: "50px",
              width: "140px",
            },
          })}
          className={classes.button}
          startIcon={<ReactLogoggplay />}
        >
          <Grid>
            <Typography className={classes.downloadText}>
              Télécharger sur
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 700,
                textTransform: "capitalize",
                marginTop: "-4px",
                fontFamily: "metropolis-Medium",
              }}
            >
              <a
                style={{ textDecoration: "none" }}
                className={classes.downloadTextStyle}
                href={URL_GOOGLE_PLAY}
              >
                Google Play
              </a>
            </Typography>
          </Grid>
        </Button>
      </Grid>
    </>
  );
};
