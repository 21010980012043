import React from "react";

export const ggPlayLogo = () => {
	return (
		<svg
			width="24"
			height="26"
			viewBox="0 0 24 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_ii_116_15131)">
				<path
					d="M0.463001 0.397082C0.172001 0.705082 -4.27246e-07 1.18308 -4.27246e-07 1.80208V23.9181C-4.27246e-07 24.5381 0.172001 25.0151 0.463001 25.3231L0.537 25.3951L12.926 13.0061V12.8601V12.7141L0.537 0.324082L0.463001 0.397082Z"
					fill="url(#paint0_linear_116_15131)"
				/>
				<path
					d="M17.055 17.1371L12.926 13.0061V12.8601V12.7141L17.056 8.58408L17.149 8.63708L22.042 11.4171C23.439 12.2111 23.439 13.5101 22.042 14.3051L17.149 17.0851L17.055 17.1371Z"
					fill="url(#paint1_linear_116_15131)"
				/>
				<g filter="url(#filter1_i_116_15131)">
					<path
						d="M17.149 17.0841L12.925 12.8601L0.462998 25.3231C0.922998 25.8111 1.684 25.8711 2.541 25.3851L17.149 17.0841Z"
						fill="url(#paint2_linear_116_15131)"
					/>
				</g>
				<path
					d="M17.149 8.63608L2.541 0.336075C1.684 -0.150925 0.922998 -0.0899245 0.462998 0.398075L12.926 12.8611L17.149 8.63608Z"
					fill="url(#paint3_linear_116_15131)"
				/>
			</g>
			<defs>
				<filter
					id="filter0_ii_116_15131"
					x="0"
					y="0"
					width="23.0897"
					height="25.7207"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="-0.15" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_116_15131"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="0.15" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_innerShadow_116_15131"
						result="effect2_innerShadow_116_15131"
					/>
				</filter>
				<filter
					id="filter1_i_116_15131"
					x="0.462997"
					y="12.8601"
					width="16.686"
					height="12.8607"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="-0.15" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_116_15131"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_116_15131"
					x1="11.8278"
					y1="1.56808"
					x2="-4.9542"
					y2="18.3501"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00A0FF" />
					<stop offset="0.0066" stopColor="#00A1FF" />
					<stop offset="0.2601" stopColor="#00BEFF" />
					<stop offset="0.5122" stopColor="#00D2FF" />
					<stop offset="0.7604" stopColor="#00DFFF" />
					<stop offset="1" stopColor="#00E3FF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_116_15131"
					x1="23.8605"
					y1="12.8601"
					x2="-0.335404"
					y2="12.8601"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFE000" />
					<stop offset="0.4087" stopColor="#FFBD00" />
					<stop offset="0.7754" stopColor="#FFA500" />
					<stop offset="1" stopColor="#FF9C00" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_116_15131"
					x1="14.8551"
					y1="15.154"
					x2="-7.9034"
					y2="37.9125"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF3A44" />
					<stop offset="1" stopColor="#C31162" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_116_15131"
					x1="-2.6756"
					y1="-6.96412"
					x2="7.4867"
					y2="3.19818"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#32A071" />
					<stop offset="0.0685" stopColor="#2DA771" />
					<stop offset="0.4762" stopColor="#15CF74" />
					<stop offset="0.8009" stopColor="#06E775" />
					<stop offset="1" stopColor="#00F076" />
				</linearGradient>
			</defs>
		</svg>
	);
};
