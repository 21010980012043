import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  title: {
    "&.MuiTypography-root": {
      fontFamily: "Marianne-Bold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      color: "#161616",
    },
  },
  paragraphe: {
    "&.MuiTypography-root": {
      fontFamily: "Marianne-Regular",
      fontSize: "16px",
      fontStyle: "normal",
      lineHeight: "24px",
      color: "#161616",
      paddingTop: "15px",
      flex: "none",
      order: "1",
      alignSelf: "stretch",
    },
  },

  button: {
    "&.MuiButton-root ": {},
    "&.MuiButton-root:hover": {
      backgroundColor: "black",
    },
  },
  downloadText: {
    "&.MuiTypography-root": {
      fontFamily: "Marianne-Regular",
      fontSize: "10px",
      textTransform: "none",
    },
  },
  downloadTextStyle: {
    color: "#FFFFFF",
    fontFamily: "Marianne-Bold",
	display: "flex",
  },
});
